import { ITerritory } from "../../_helpers/models";
import IcelandMap from "../../_assets/areas/gallery/IcelandMap";
import SvalbardMap from "../../_assets/areas/gallery/svalbardMap"
import Laplandmap from "../../_assets/areas/gallery/laplandMap"
import { AGHWhite } from "../../_assets/logo";
import { Gljufrabui, Seljalandsfoss, Svartifoss1 } from "../../_assets/areas/gallery/Iceland/index"
import { GljufrabuiAudio, SeljalandsfossAudio, Svartifoss1Audio } from "../../_assets/areas/interactivePanel/iceland/index"
import {
  SvalbardAudio1, SvalbardImage1,
  SvalbardAudio2, SvalbardImage2,
  SvalbardAudio3, SvalbardImage3,
  SvalbardAudio4, SvalbardImage4,
  SvalbardAudio5, SvalbardImage5,
  SvalbardAudio6, SvalbardImage6,
  SvalbardAudio7, SvalbardImage7,
  SvalbardAudio8, SvalbardImage8,
  SvalbardAudio9, SvalbardImage9,
  SvalbardAudio10, SvalbardImage10,
  SvalbardAudio11, SvalbardImage11,
  SvalbardAudio12, SvalbardImage12,
  SvalbardAudio13, SvalbardImage13,
} from "../../_assets/areas/interactivePanel/svalbard/index"
export const arcticTerritoryIsland: ITerritory = {
  mapC: IcelandMap,
  name: "teritory_arctic_iceland_name",
  slug: "iceland",
  description: "teritory_arctic_iceland_subname",
  routes: [
    /*video*/
    {
      id: "iceland-1",
      name: "Surtshellir",
      videoId: "H8Ne5Fw4o6Y",

    },

    {
      id: "iceland-2",
      name: "Reykjanes Lighthouse",
      videoId: "Ja1yFgGNrsY",
      pointId: "iceland-2",
    },
    {
      //This should be fixed so every ID is unique.
      id: "iceland-2b",
      name: "Valahnúkamöl",
      videoId: "x-ZKc7-9tIY",
      pointId: "iceland-2",
    },
    {
      id: "iceland-3",
      name: "Gullfoss",
      videoId: "I_Rh06U3Hog",
    },
    {
      id: "iceland-4",
      name: "Geysir",
      videoId: "QFx1VLcZvho",
    },
    {
      id: "iceland-5",
      name: "Þingvellir (Thingvellir National Park)",
      videoId: "KpxImh5CcPA",
    },
    {
      id: "iceland-6",
      name: "Kerið",
      videoId: "-i0hH2LYD40",
    },

    /*obrazy*/
    {
      id: "iceland-8",
      name: "Seljalandsfoss",
      imageUrl: Seljalandsfoss,
      audioId: SeljalandsfossAudio,
      pointId: "iceland-8",
    },
    {
      id: "iceland-8b",
      name: "Gljufrabui",
      imageUrl: Gljufrabui,
      audioId: GljufrabuiAudio,
      pointId: "iceland-8",
    },
    {
      id: "iceland-9",
      name: "Skarðsfjörður",
      imageUrl: Svartifoss1,
      audioId: Svartifoss1Audio
    },

  ],
  info: {
    description: "teritory_arctic_iceland_descr",
    partnerLogo: AGHWhite,
    partnerUrl: "https://www.agh.edu.pl/",
    linkMain: "area_generic_appendix",
  },
};

export const arcticTerritorySvalbard: ITerritory = {
  mapC: SvalbardMap,
  name: "teritory_arctic_svalbard_name",
  slug: "svalbard",
  description: "teritory_arctic_svalbard_subname",
  routes: [
    /*obrazy*/
    {
      id: "svalbard-1",
      name: "Longyearbyen - Gjestehuset 102",
      imageUrl: SvalbardImage1,
      audioId: SvalbardAudio1,
      pointId: "svalbard-1"
    },
    {
      id: "svalbard-3",
      name: "Sarkofagen",
      imageUrl: SvalbardImage3,
      audioId: SvalbardAudio3,
      pointId: "svalbard-1"
    },
    {
      id: "svalbard-4",
      name: "Trollsteinen",
      imageUrl: SvalbardImage4,
      audioId: SvalbardAudio4,
      pointId: "svalbard-1"
    },
    {
      id: "svalbard-5",
      name: "Svalbard Lufthavn, Longyearbyen",
      imageUrl: SvalbardImage5,
      audioId: SvalbardAudio5,
      pointId: "svalbard-1"
    },
    {
      id: "svalbard-13",
      name: "Longyeardalen",
      imageUrl: SvalbardImage13,
      audioId: SvalbardAudio13,
      pointId: "svalbard-1"

    },

    {
      id: "svalbard-2",
      name: "Pyramiden",
      imageUrl: SvalbardImage2,
      audioId: SvalbardAudio2
    },
    {
      id: "svalbard-6",
      name: "Isfjord radio",
      imageUrl: SvalbardImage6,
      audioId: SvalbardAudio6,
      pointId: "svalbard-7"
    },
    {
      id: "svalbard-7",
      name: "Isfjord radio 2",
      imageUrl: SvalbardImage7,
      audioId: SvalbardAudio7,
      pointId: "svalbard-7"
    },

    {
      id: "svalbard-8",
      name: "Kokerineset",
      imageUrl: SvalbardImage8,
      audioId: SvalbardAudio8,
      pointId: "svalbard-8"
    },
    {
      id: "svalbard-11",
      name: "Barentsburg",
      imageUrl: SvalbardImage11,
      audioId: SvalbardAudio11,
      pointId: "svalbard-8"
    },
    {
      id: "svalbard-9",
      name: "Colesbukta",
      imageUrl: SvalbardImage9,
      audioId: SvalbardAudio9,
      pointId: "svalbard-12"
    },
    {
      id: "svalbard-12",
      name: "Hollendarbukta",
      imageUrl: SvalbardImage12,
      audioId: SvalbardAudio12,
      pointId: "svalbard-12"
    },
    {
      id: "svalbard-10",
      name: "Festin gsodden",
      imageUrl: SvalbardImage10,
      audioId: SvalbardAudio10
    }


  ],
  info: {
    description: "teritory_arctic_svalbard_descr",
    partnerLogo: AGHWhite,
    partnerUrl: "https://www.agh.edu.pl/",
    linkMain: "area_generic_appendix",
  }
}
export const arcticTerritoryLapland: ITerritory = {
  mapC: Laplandmap,
  name: "teritory_arctic_lapland_name",
  slug: "lapland",
  description: "teritory_arctic_lapland_subname",
  routes: [
    /*video*/
    {
      id: "lapland-1",
      name: "Yllästunturin luontokeskus Kellokas",
      videoId: "1KEjvtBVk30",
      pointId: "lapland-1"
    },
    {
      id: "lapland-2",
      name: "Yllästunturin luontokeskus Kellokas",
      videoId: "BT4cdCJ9W3w",
      pointId: "lapland-1"
    },
    {
      id: "lapland-3",
      name: "Yllästunturin luontokeskus Kellokas",
      videoId: "Eb1etThu-Fs",
      pointId: "lapland-1"
    },
    {
      id: "lapland-4",
      name: "Yllästunturin luontokeskus Kellokas",
      videoId: "RnJcURS807w",
      pointId: "lapland-1"
    },
    {
      id: "lapland-5",
      name: "Pallas-Yllästunturi National Park",
      videoId: "2bX0MtMORO8",
    },
    {
      id: "lapland-6",
      name: "Tjasskávárvásj",
      videoId: "CN2h6RoD0w0",
    },
    {
      id: "lapland-7",
      name: "By the Rådnik",
      videoId: "xZ4gRXG17k8",
      pointId: "lapland-10"
    },
    {
      id: "lapland-8",
      name: "Skárjá",
      videoId: "vxWLVnnTcVo",
    },
    {
      id: "lapland-9",
      name: "Spökstenen",
      videoId: "N8CLz3LtJr4",
    },
    {
      id: "lapland-10",
      name: "Gammelstads kyrkstad",
      videoId: "IjaVZP0aki4",
      pointId: "lapland-7"
    },
  ],
  info: {
    description: "teritory_arctic_lapland_descr",
    partnerLogo: AGHWhite,
    partnerUrl: "https://www.agh.edu.pl/",
    linkMain: "area_generic_appendix",
  },
};
