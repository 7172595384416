import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swiper from "swiper";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import {
  useTerritoryContext,
  useTerritoryActionsContext,
} from "../../../context/Terrritory.context";
import { ITerritoryRoute } from "../../../_helpers/models";

const SliderTrails = () => {
  const { t } = useTranslation();

  const { territoryData, activeTerritoryRoute } = useTerritoryContext();
  const { setActiveTerritoryRoute } = useTerritoryActionsContext();

  const [routesLength] = useState<number>(territoryData.routes.length);
  const [activeId, setActiveId] = useState<number>(activeTerritoryRoute);


  useEffect(() => {
    setActiveTerritoryRoute(activeId);
  }, [activeId, setActiveTerritoryRoute]);

  useEffect(() => {
    new Swiper(".trails__slider", {
      initialSlide: activeId,
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: ".trails__slider--next",
        prevEl: ".trails__slider--prev",
      },
      slideClass: "trails__slider-slide",
      wrapperClass: "trails__slider-wrapper",
      loop: true,
      allowTouchMove: false,
    });
  }, [activeId]);

  const onNextClick = () => {
    if (activeId === 0) {
      setActiveId(routesLength - 1);
    } else {
      setActiveId(activeTerritoryRoute - 1);
    }
  };

  const onPrevClick = () => {
    if (activeId === routesLength - 1) {
      setActiveId(0);
    } else {
      setActiveId(activeTerritoryRoute + 1);
    }
  };

  return (
    <>
      <div>
        <div className="trails__slider swiper-container">
          <div className="trails__slider-wrapper swiper-wrapper">
            {territoryData.routes.map((trail: ITerritoryRoute, key: number) => (

              <div key={key} className="trails__slider-slide swiper-slide">
                <p> {t(trail.name)}</p>
              </div>

            ))}
          </div>

          <div className="slider__buttons slider__buttons--horizontal">
            <div
              className="trails__slider--prev slider__button slider__button--prev"
              onClick={onNextClick}
            >
              <div className="slider__icon slider__icon--blue slider__icon--smaller">
                <ArrowBackIcon
                  style={{
                    display: "block",
                    color: "#fff",
                    fontSize: "34px",
                  }}
                  key="arrowBack"
                />
              </div>
            </div>
            <div className="trails__slider--next slider__button slider__button--next">
              <div
                className="slider__icon slider__icon--blue slider__icon--smaller"
                onClick={onPrevClick}
              >
                <ArrowForwardIcon
                  style={{
                    display: "block",
                    color: "#fff",
                    fontSize: "34px",
                  }}
                  key="arrowFwd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SliderTrails;
