import React from "react";

import { TerritoryPartners } from ".";
import { useTranslation } from "react-i18next";

import { useTerritoryContext } from "../../context/Terrritory.context";

const TerritoryInfo = () => {
  const { territoryData, activeTerritoryRoute } = useTerritoryContext();
  const { info, slug, routes } = territoryData;
  const { t } = useTranslation();

  const pointDescription = t(`teritory_${slug}_point_${routes[activeTerritoryRoute].id}`)
  //Falback to area description if route point description is not avail.
  // eslint-disable-next-line eqeqeq
  const description = pointDescription == `teritory_${slug}_point_${routes[activeTerritoryRoute].id}` ? t(info.description) : pointDescription;

  return (
    <div key={`teritory_${slug}_point_${routes[activeTerritoryRoute].id}`} className="territoryInfo">
      <div className="territoryInfo__title">
        <h2>{t(territoryData.name)}</h2>
        <h3>{t(territoryData.description)}</h3>
      </div>
      <TerritoryPartners />

      <p className="territoryInfo__description">

        {description}
      </p>

      {info.quotation && (
        <p className="territoryInfo__description">
          <span className="territoryInfo__description-quotation">{info.quotation}</span>
        </p>
      )}

      {info.author && (
        <p className="territoryInfo__description">
          <span>{info.author}</span>{" "}
        </p>
      )}

      <p className="territoryInfo__linkMain">{t(info.linkMain)}</p>

      {info.linkAdditional && (
        <p className="territoryInfo__linkAdditional">
          {info.linkAdditional}
        </p>
      )}

      <div className="territoryInfo__links">
        {info.links?.map((link, index) => (
          <>
            <a href={link.url}>{link.name}</a >
            <span>/</span>
          </>
        ))}
      </div>
    </div >
  );
};

export default TerritoryInfo;
