import { ITerritory } from "../../_helpers/models";
import HimalayasMap from "../../_assets/areas/gallery/HimalayasMap";
import {
  Him1,
  Him2,
  Him3a,
  Him3b,
  Him4,
  Him5,
  Him6,
  Him7a,
  Him7b,
  Him8,
  Him9,
  Him10,
  Him11a,
  Him11b,
  Him12a,
  Him12b,
  Him12c,
  Him13,
  Him14a,
  Him14b,
  Him15,
  Him16,
  Him17,
  Him18,
  Him19,
  Him20,
  Him21,
  HimAudio1,
  HimAudio2,
  HimAudio3a,
  HimAudio3b,
  HimAudio4,
  HimAudio5,
  HimAudio6,
  HimAudio8,
  HimAudio9,
  HimAudio10,
  HimAudio11a,
  HimAudio11b,
  HimAudio12a,
  HimAudio12b,
  HimAudio13,
  HimAudio14b,
  HimAudio16,
  HimAudio17,
  HimAudio18,
  HimAudio19,
  HimAudio20,
  HimAudio21,
} from "../../_assets/areas/interactivePanel/Manaslu";
import { RaperImg, RaperSound } from "../../_assets/video";
import { AoAWite } from "../../_assets/logo";

export const mountainsTerritoryHimalaya: ITerritory = {
  mapC: HimalayasMap,
  name: "teritory_mountains_himalayas_name",
  slug: "himalayas",
  description: "teritory_mountains_himalayas_subname",
  routes: [
    {
      id: "himalayas-1",
      name: "Soti Khola",
      imageUrl: Him1,
      audioId: HimAudio1,
    },
    {
      id: "himalayas-2",
      name: "Lapubesi",
      imageUrl: Him2,
      audioId: HimAudio2,
    },
    {
      id: "himalayas-3",
      name: "Machhakhola 1",
      imageUrl: Him3a,
      audioId: HimAudio3a,
      pointId: "himalayas-3",
    },
    {
      id: "himalayas-3b",
      name: "Machhakhola 2",
      imageUrl: Him3b,
      audioId: HimAudio3b,
      pointId: "himalayas-3",
    },
    {
      id: "himalayas-4",
      name: "Philim – Deng",
      imageUrl: Him4,
      audioId: HimAudio4,
    },
    {
      id: "himalayas-5",
      name: "Ghap – Namrung",
      imageUrl: Him5,
      audioId: HimAudio5,
    },
    {
      id: "himalayas-6",
      name: "Namrung",
      imageUrl: Him6,
      audioId: HimAudio6,
    },
    {
      id: "himalayas-7",
      name: "Namrung – Lho 1",
      imageUrl: Him7a,
      pointId: "himalayas-7",
    },
    {
      id: "himalayas-7b",
      name: "Namrung – Lho 2",
      imageUrl: Him7b,
      pointId: "himalayas-7",
    },
    {
      id: "himalayas-8",
      name: "Lho",
      imageUrl: Him8,
      audioId: HimAudio8,
    },
    {
      id: "himalayas-9",
      name: "Shyala",
      imageUrl: Him9,
      audioId: HimAudio9,
    },
    {
      id: "himalayas-10",
      name: "Shyala – Samagaun",
      imageUrl: Him10,
      audioId: HimAudio10,
    },
    {
      id: "himalayas-11",
      name: "Samagaun 1",
      imageUrl: Him11a,
      audioId: HimAudio11a,
      pointId: "himalayas-11",
    },
    {
      id: "himalayas-11b",
      name: "Samagaun 2",
      imageUrl: Him11b,
      audioId: HimAudio11b,
      pointId: "himalayas-11",
    },
    {
      id: "himalayas-12",
      name: "Pungyen Monastery 1",
      imageUrl: Him12a,
      audioId: HimAudio12a,
      pointId: "himalayas-12",
    },
    {
      id: "himalayas-12b",
      name: "Pungyen Monastery 2",
      imageUrl: Him12b,
      audioId: HimAudio12b,
      pointId: "himalayas-12",
    },
    {
      id: "himalayas-12c",
      name: "Pungyen Monastery 3",
      imageUrl: Him12c,
      pointId: "himalayas-12",
    },
    {
      id: "himalayas-13",
      name: "Birendra Lake",
      imageUrl: Him13,
      audioId: HimAudio13,
    },
    {
      id: "himalayas-14",
      name: "Samdo 1",
      imageUrl: Him14b,
      audioId: HimAudio14b,
      pointId: "himalayas-14",
    },
    {
      id: "himalayas-14b",
      name: "Samdo 2",
      imageUrl: Him14a,
      pointId: "himalayas-14",
    },

    {
      id: "himalayas-15",
      name: "Tibet Border",
      imageUrl: Him15,
    },
    {
      id: "himalayas-16",
      name: "Dharmasala",
      imageUrl: Him16,
      audioId: HimAudio16,
    },
    {
      id: "himalayas-17",
      name: "Larkya-Pass",
      imageUrl: Him17,
      audioId: HimAudio17,
    },
    {
      id: "himalayas-18",
      name: "Lakya-la Pass",
      imageUrl: Him18,
      audioId: HimAudio18,
    },
    {
      id: "himalayas-19",
      name: "Bhimtang",
      imageUrl: Him19,
      audioId: HimAudio19,
    },
    {
      id: "himalayas-20",
      name: "Bhimtang – Gho",
      imageUrl: Him20,
      audioId: HimAudio20,
    },
    {
      id: "himalayas-21",
      name: "Tilije",
      imageUrl: Him21,
      audioId: HimAudio21,
    },
    // temporarily hidden
    // {
    //   id: "himalayas-22",
    //   name: "Besishahar",
    //   imageUrl: Him22,
    // },
  ],
  info: {
    description: "teritory_mountains_himalayas_descr",
    author: "© Karolina Jaruszewska",
    quotation: "",
    linkMain: "area_generic_appendix",
    linkAdditional:
      "Special thanks to guide Ganesh Adhikari for precious knowledge.",
    links: [
      {
        name: "https://www.acevisionnepal.com",
        url: "https://www.acevisionnepal.com",
      },
    ],
  },
  extras: {
    image: RaperImg,
    sound: RaperSound,
    description: "Nepal raper freestyle",
    routeId: 9,
  },
};
