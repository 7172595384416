import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { UserAgentProvider, UserAgent } from "@quentin-sommer/react-useragent";
import { Pannellum } from "pannellum-react";
import YouTube, { Options } from "react-youtube";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import {
  TerritoryMobileVRInfo,
  TerritoryNavigation,
  TerritoryPanel,
  TerritoryVRInfo,
} from "./";
import AudioBtn from "./TerritoryPanelElements/AudioBtn";


import { IParams } from "../../_helpers/models";
import {
  useTerritoryContext,
  useTerritoryActionsContext,
} from "../../context/Terrritory.context";
import {
  arcticTerritoryIsland,
  arcticTerritoryLapland,
  arcticTerritorySvalbard,
  mountainsTerritoryHimalaya,
} from "../../_data/areaPoints";
import { useDesktop } from "../../hooks/useDesktop";

const Territory = () => {
  const {
    territoryData,
    activeTerritoryRoute,
    territoryToggles,
  } = useTerritoryContext();
  const { setTerritoryData } = useTerritoryActionsContext();

  const isDesktop = useDesktop();
  const { t } = useTranslation();

  const { name } = useParams<IParams>();

  const opts: Options = {
    playerVars: {
      autoplay: 1,
      showinfo: 0,
      controls: 0,
      modestbranding: 1,
      playsinline: 0,
      rel: 1,
      fs: 0,
      iv_load_policy: 1,
      disablekb: 1,
      loop: 1,
    },
  };

  useEffect(() => {
    switch (name) {
      case "iceland": {
        setTerritoryData(arcticTerritoryIsland);
        break;
      }
      case "himalayas": {
        setTerritoryData(mountainsTerritoryHimalaya);
        break;
      }
      case "laponia": {
        setTerritoryData(arcticTerritoryLapland);
        break;
      }
      case "svalbard": {
        setTerritoryData(arcticTerritorySvalbard);
        break;
      }
    }
  }, [name, setTerritoryData]);

  return (
    <section className="video">
      <div>{isDesktop ? <TerritoryVRInfo /> : <TerritoryMobileVRInfo />}</div>

      <div
        className={`video__panel${territoryToggles.point ? " video__panel--active" : ""
          }`}
      >
        <TerritoryNavigation />

        {territoryToggles?.point && <TerritoryPanel />}
      </div>

      <>
        {territoryData.routes[activeTerritoryRoute].audioId ? (
          <div className="video__audio">
            <AudioBtn
              url={
                territoryData.routes[activeTerritoryRoute].audioId as string
              }
              autoplay
            />
          </div>
        ) : (
          <div className="video__audio">
            {territoryData.routes[activeTerritoryRoute].videoId ? (
              <div>
              </div>
            ) : (
              <div className="video__audioBtn">
                <VolumeOffIcon />
              </div>
            )}
          </div>
        )}
      </>


      {territoryData.routes[activeTerritoryRoute].videoId && (
        <UserAgentProvider ua={window.navigator.userAgent}>
          <div>
            <UserAgent ios>
              <div className="video__mobileCommunique">
                {t("mobileCommunique")}
              </div>
            </UserAgent>
            <UserAgent computer android>
              <YouTube
                videoId={territoryData?.routes[activeTerritoryRoute]?.videoId}
                className="video__fullscreen"
                opts={opts}
              />
            </UserAgent>
          </div>
        </UserAgentProvider>
      )}

      {isDesktop ? (
        <>
          {territoryData.routes[activeTerritoryRoute].imageUrl && (
            <Pannellum
              width="100%"
              height="100vh"
              pitch={0}
              yaw={360}
              hfov={110}
              vaov={90}
              minPitch={-45}
              maxPitch={45}
              autoLoad
              image={territoryData?.routes[activeTerritoryRoute]?.imageUrl}
            />
          )}
        </>
      ) : (
        <>
          {territoryData.routes[activeTerritoryRoute].imageUrl && (
            <Pannellum
              width="100%"
              height="100vh"
              pitch={0}
              yaw={360}
              hfov={100}
              vaov={90}
              minPitch={-35}
              maxPitch={35}
              autoLoad
              image={territoryData?.routes[activeTerritoryRoute]?.imageUrl}
            />
          )}
        </>
      )}
    </section>
  );
};

export default Territory;
