import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ITypeOfArea } from "../../../_helpers/models";

interface IProps {
  area: ITypeOfArea;
  areaPath: string;
}
const AreaGlobe = (props: IProps) => {
  const { t } = useTranslation();
  const { area, areaPath } = props;
  const history = useHistory();

  return (
    <>
      <div className="areaGlobeContent">
        {area.map && (
          <div className="areaMap">
            <img src={area.map} alt="Map" />
          </div>
        )}

        <h2>{t(area.name)}</h2>
        <h3>{t(area.subname)}</h3>

        {area.slug && (
          <div className="areaGlobe__partners-element-button">
            <div
              className="areaGlobe__partners-element-button-buttonVr"
              onClick={() => history.push(`/project/${areaPath}/${area.slug}`)}
            >
              {t("see_vr")}
            </div>
            <br />
          </div>
        )}

        <div className="areaGlobe__informations-separator"></div>

        <p>{t(area.description)}</p>

        {area.map && (
          <div className="areaMap areaMapMobile">
            <img src={area.map} alt="Map" />
          </div>
        )}

        <span className={"generic__linklike_subtext"}>{t(area.link)}</span>

        {area.partnerLogo && (
          <>
            <div className="areaGlobe__informations-separator areaGlobe__informations-separator--margin"></div>
            <div className="areaGlobe__partners">
              <span>{t("project_partners")}</span>
              <div className="areaGlobe__partners-separator"></div>

              <div className="areaGlobe__partners-element">
                <img src={area.partnerLogo} alt="" />
              </div>

              <div className="areaGlobe__partners-separator"></div>

              <div className="areaGlobe__partners-element">
                <a
                  className="areaGlobe__partners-element-buttonWebsite"
                  href={area.partnerUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("see_website")}
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AreaGlobe;
