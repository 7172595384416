import React from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@material-ui/icons/Close";
import { withStyles, Theme, Dialog, DialogActions } from "@material-ui/core";

import { vrInfoImg1, vrInfoImg2 } from "../../_assets/video";

export default function TerritoryVRInfo() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);

  const VRDialog = withStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(2),
      width: "100vw",
      height: "100vh",
    },
  }))(Dialog);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="VRInfo">
      <VRDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="VRInfo__dialog"
        maxWidth={false}
      >
        <DialogActions>
          <div className="VRInfo__dialog__close" onClick={handleClose}>
            <CloseIcon
              className="VRInfo__dialog__close-icon"
              style={{ fontSize: "28px" }}
            />
          </div>
        </DialogActions>

        <div className="VRInfo__dialog-content">
          <div className="VRInfo__dialog-content-element">
            <img src={vrInfoImg1} alt="VR Info" />
            <h2>{t("see_vr_header")}</h2>
          </div>
          <div className="VRInfo__dialog-content-element">
            <img src={vrInfoImg2} alt="VR Info" />
            <p>
              {t("see_vr_description")}
            </p>
          </div>

          <div className="VRInfo__dialog-content-button" onClick={handleClose}>
            <span>{t("see_vr")}</span>
          </div>
        </div>
      </VRDialog>
    </div>
  );
}
