export interface IPlace {
  name: string;
  image: string;
  path: string;
  description: string;
  globe: string;
  data: IData;
  isAvailable: boolean;
}

export interface IMap {
  CN: number;
}

export interface IPanoramas {
  image: string;
  sounds: any;
  title: string;
}

export interface IContext {
  place: IPlace;
  data: IData;
}

export interface IData {
  panoramas: IPanoramas[];
  regions: IRegions[];
  typeOfArea: ITypeOfArea[];
}

export interface IRegions {
  name: string;
  subname: string;
  index: number;
  gallery: IAreaGallery[];
}

export interface IAreaGallery {
  id: number;
  src: string;
  desc: string;
}

export interface ITypeOfArea {
  slug?: string;
  name: string;
  subname: string;
  description: string;
  link: string;
  mapC?: any;
  map?: string;
  partnerLogo?: string;
  partnerUrl?: string;
  index: number;
  locationY?: string;
  locationX?: string;
  points?: IPointsOnTheMap[];
  territory?: ITerritory;
  positionTab?: string;
}

export interface IPointsOnTheMap {
  name: string;
  id: string;
  path: string;
  pointY: string;
  pointX: string;
  video: string;
}

export enum Places {
  arctic = "teritory_arctic",
  parks = "teritory_parks",
  mountains = "teritory_mountains",
  cities = "teritory_cities",
}

export interface ITerritory {
  mapC: any;
  name: string;
  slug: string;
  description: string;
  routes: ITerritoryRoute[];
  info: ITerritoryInfo;
  extras?: IExtras;
}

export interface IExtras {
  image: string;
  description: string;
  sound: string;
  routeId: number;
}

export interface ITerritoryRoute {
  id: string;
  name: string;
  imageUrl?: string;
  videoId?: string;
  audioId?: string;
  //Setting it will overide the active point to of other route.
  pointId?: string;
}

export interface ITerritoryInfo {
  description: string;
  author?: string;
  quotation?: string;
  partnerLogo?: string;
  partnerUrl?: string;
  linkMain: string;
  linkAdditional?: string;
  links?: ITerritoryLinks[];
}

export interface ITerritoryLinks {
  name: string;
  url: string;
}

export interface IParams {
  area: string;
  name: string;
}
