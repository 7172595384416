import React, { useState } from "react";
import Sound from "react-sound";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";

export enum BtnAction {
  playing = "PLAYING",
  stopped = "STOPPED",
}

interface IProps {
  url: string;
  autoplay?: boolean;
}

const AudioBtn = (props: IProps) => {
  const { url, autoplay } = props;
  const [status, setStatus] = useState(
    autoplay ? BtnAction.playing : BtnAction.stopped
  );

  const onBtnClick = () => {
    if (status === BtnAction.stopped) {
      return setStatus(BtnAction.playing);
    }

    setStatus(BtnAction.stopped);
  };

  return (
    <div className="video__audioBtn" onClick={onBtnClick}>
      <Sound
        url={url}
        onLoading={() => setStatus(BtnAction.stopped)}
        playStatus={Sound.status[status]}
        onFinishedPlaying={() => setStatus(BtnAction.stopped)}
      />
      {status === BtnAction.playing ? <StopIcon /> : <PlayArrowIcon />}
    </div>
  );
};

export default AudioBtn;
