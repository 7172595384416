import IcelandMap from "./gallery/IcelandMap";
import SvalbardMap from "./gallery/svalbardMap"
import map from "./gallery/icelandMap.svg";
import logo from "../areas/logoAGH.png";
import map2 from "./gallery/svalbard.svg"
import Lapland from "./gallery/laplandMap";
import map1 from "./gallery/laplandMap.svg"

import {
  Svartifoss,
  Gullfoss,
  UnescoReykjanesGeopark,
  Valahnukur,
  Geysir,
} from "./gallery/Iceland";
import {
  Kopalnia,
  Baretntsburg,
  Colesbukta,
  Jakininia_lodowcowa_larsbreen,
  Trolstein,
  Sarkofagen,
  Starostin_hut_at_russekeila,
  Gronfjorden,
  Klif_w_poblizu_ujscia_rzeki_Bjorndaselva,
  Grumantdalen
} from "./gallery/svalbard"

import {
  bohemian_waxwing,
  park1,
  park2,
  park3,
  park4,
  delta_rzeki_rapa,
  skarja,
  w_drodze_do_sitoalvsborn,
  widok_na_doline_rapa1,
  widok_na_doline_rapa2,
} from "./gallery/lapland"
export const arcticImages = {
  mapC: IcelandMap,
  map: map,
  logo: logo,
  photos: [
    { id: 0, src: Svartifoss, desc: "gallery_iceland_svartifoss" },
    { id: 1, src: Gullfoss, desc: "gallery_iceland_gullfoss" },
    { id: 2, src: UnescoReykjanesGeopark, desc: "gallery_iceland_geopark" },
    { id: 3, src: Valahnukur, desc: "gallery_iceland_cliff" },
    { id: 4, src: Geysir, desc: "gallery_iceland_geysir" },
  ],
};
export const arcticImagesSwalbard = {
  mapC: SvalbardMap,
  map: map2,
  logo: logo,
  photos: [
    { id: 0, src: Kopalnia, desc: "gallery_svalbard_Kopalnia" },
    { id: 1, src: Baretntsburg, desc: "gallery_svalbard_Baretntsburg" },
    { id: 2, src: Colesbukta, desc: "gallery_svalbard_Colesbukta" },
    { id: 3, src: Jakininia_lodowcowa_larsbreen, desc: "gallery_svalbard_Jakininia_lodowcowa_larsbreen" },
    { id: 4, src: Trolstein, desc: "gallery_svalbard_Trolstein" },
    { id: 5, src: Sarkofagen, desc: "gallery_svalbard_Sarkofagen" },
    { id: 6, src: Starostin_hut_at_russekeila, desc: "gallery_svalbard_Starostin_hut_at_russekeila" },
    { id: 7, src: Gronfjorden, desc: "gallery_svalbard_Gronfjorden" },
    { id: 8, src: Klif_w_poblizu_ujscia_rzeki_Bjorndaselva, desc: "gallery_svalbard_Klif_w_poblizu_ujscia_rzeki_Bjorndaselva" },
    { id: 9, src: Grumantdalen, desc: "gallery_svalbard_Grumantdalen" },
  ],
};

export const arcticImagesLaponia = {
  mapC: Lapland,
  map: map1,
  logo: logo,
  photos: [
    { id: 0, src: bohemian_waxwing, desc: "gallery_lapland_bohemian_waxwing" },
    { id: 1, src: park1, desc: "gallery_lapland_park1" },
    { id: 2, src: park2, desc: "gallery_lapland_park2" },
    { id: 3, src: park3, desc: "gallery_lapland_park3" },
    { id: 4, src: park4, desc: "gallery_lapland_park4" },
    { id: 5, src: delta_rzeki_rapa, desc: "gallery_lapland_delta_rzeki_rapa" },
    { id: 6, src: skarja, desc: "gallery_lapland_skarja" },
    { id: 7, src: w_drodze_do_sitoalvsborn, desc: "gallery_lapland_w_drodze_do_sitoalvsborn" },
    { id: 8, src: widok_na_doline_rapa1, desc: "gallery_lapland_widok_na_doline_rapa1" },
    { id: 9, src: widok_na_doline_rapa2, desc: "gallery_lapland_widok_na_doline_rapa2" },
  ],
};


