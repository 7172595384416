import React from "react";

import { useTerritoryContext } from "../../../context/Terrritory.context";

interface IProps {
  map: any;
}

const InteractiveMap = (props: IProps) => {
  const { territoryData, activeTerritoryRoute } = useTerritoryContext();

  let activePin = territoryData.routes[activeTerritoryRoute].id;
  // eslint-disable-next-line eqeqeq
  if (territoryData.routes[activeTerritoryRoute].pointId) {
    activePin = territoryData.routes[activeTerritoryRoute].pointId!;
  }

  return (
    <div className="interactiveMap">
      <props.map active={activePin} />
    </div>
  );
};

export default InteractiveMap;
