import React, { useCallback, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import AreaGlobeContent from "./AreaGlobeContent";
import AreaLocation from "./AreaLocation";
import AreaOption from "./AreaOption";

import { ITypeOfArea, IPlace } from "../../../_helpers/models";
import { Typography } from "@material-ui/core";
import { ArrowSelect, PinSelect } from "../../../_assets/areas";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

interface IProps {
  areas: ITypeOfArea[];
  data: IPlace;
}

const AreaGlobe = (props: IProps) => {
  const { areas, data } = props;
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [textSelectInput, setTextSelectInput] = useState("Discover!");
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [activeOption, setActiveOption] = useState(0);
  const [globeOpacity, setGlobeOpacity] = useState(1);

  const toggleSelect = useCallback(
    () => setIsOpenSelect((prevState) => !prevState),
    []
  );

  return (
    <>
      <div className="areaSelect" onClick={toggleSelect}>
        <div className="areaSelect__input">
          <img src={PinSelect} alt="pin" />
          <span>{textSelectInput}</span>
          <img src={ArrowSelect} alt="select icon" />
        </div>

        {isOpenSelect && (
          <div className="areaSelect__options">
            {areas.map((area: ITypeOfArea, index: number) => (
              <AreaOption
                area={area}
                key={index}
                handleSelectOption={() => {
                  setValue(area.index);
                  setTextSelectInput(t(area.name));
                  setActiveOption(area.index);
                }}
                selectedClassName={classnames(
                  activeOption === area.index
                    ? "areaSelect__options-option-selected"
                    : ""
                )}
              />
            ))}
          </div>
        )}
      </div>

      <section className="areaGlobe">
        <div className="areaGlobe__globe">
          <div
            onMouseLeave={() => {
              setGlobeOpacity(1);
            }}
            className="areaGlobe__globe-position"
          >
            <img
              src={data.globe}
              alt="Globe"
              onClick={() => {
                setValue(areas[0].index);
                setTextSelectInput("Discover!");
              }}
              onMouseEnter={() => {
                setGlobeOpacity(0.85);
              }}
              style={{ opacity: globeOpacity }}
            />

            {areas.map((area: ITypeOfArea, index: number) => (
              <AreaLocation
                key={index}
                area={area}
                areaPath={data.path}
                handleLocation={() => {
                  setValue(area.index);
                }}
              />
            ))}
          </div>
        </div>

        <div className="areaGlobe__informations">
          {areas.map((area, key) => (
            <div key={key} className={area.positionTab}>
              <TabPanel value={value} index={area.index}>
                <AreaGlobeContent area={area} areaPath={data.path} />
              </TabPanel>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default AreaGlobe;
