import { IData } from "../_helpers/models";
import { arcticImages, arcticImagesSwalbard, arcticImagesLaponia } from "../_assets/areas/index";
import { arcticTerritoryIsland, arcticTerritorySvalbard, arcticTerritoryLapland } from "./areaPoints";



export const arcticData: IData = {

  typeOfArea: [
    {
      name: "area_generic_header",
      subname: "area_generic_subtitle",
      description: "teritory_arctic_descr",
      link: "area_generic_appendix",
      index: 0,
      locationX: "1000%",
      locationY: "-1000%",
      positionTab: "areaGlobe__informations-center"
    },
    {
      slug: "iceland",
      mapC: arcticImages.map,
      map: arcticImages.map,
      name: "teritory_arctic_iceland_name",
      subname: "teritory_arctic_iceland_subname",
      description: "teritory_arctic_iceland_descr",
      link: "area_generic_appendix",
      partnerLogo: arcticImages.logo,
      partnerUrl: "http://kmiw.imir.agh.edu.pl/",
      locationX: "46%",
      locationY: "42%",
      index: 1,
      territory: arcticTerritoryIsland,
      positionTab: "areaGlobe__informations-start"
    },
    {
      slug: "laponia",
      mapC: arcticImagesLaponia.map,
      map: arcticImagesLaponia.map,
      name: "teritory_arctic_lapland_name",
      subname: "teritory_arctic_lapland_subname",
      description: "teritory_arctic_lapland_descr",
      link: "area_generic_appendix",
      partnerLogo: arcticImages.logo,
      partnerUrl: "http://kmiw.imir.agh.edu.pl/",
      locationX: "59%",
      locationY: "37%",
      index: 2,
      territory: arcticTerritoryLapland,
      positionTab: "areaGlobe__informations-start"
    },
    {
      slug: "svalbard",
      mapC: arcticImagesSwalbard.map,
      map: arcticImagesSwalbard.map,
      name: "teritory_arctic_svalbard_name",
      subname: "teritory_arctic_svalbard_subname",
      description: "teritory_arctic_svalbard_descr",
      link: "area_generic_appendix",
      partnerLogo: arcticImagesSwalbard.logo,
      partnerUrl: "http://kmiw.imir.agh.edu.pl/",
      locationX: "53%",
      locationY: "30%",
      index: 3,
      territory: arcticTerritorySvalbard,
      positionTab: "areaGlobe__informations-start"
    }
  ],

  regions: [
    {
      name: "teritory_arctic_iceland_name",
      subname: "teritory_arctic_iceland_subname",
      gallery: arcticImages.photos,
      index: 0,
    },
    {
      name: "teritory_arctic_lapland_name",
      subname: "teritory_arctic_lapland_subname",
      gallery: arcticImagesLaponia.photos,
      index: 1,
    },
    {
      name: "teritory_arctic_svalbard_name",
      subname: "teritory_arctic_svalbard_subname",
      gallery: arcticImagesSwalbard.photos,
      index: 2,
    },
  ],

  panoramas: [],
};
