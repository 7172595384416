import { IData } from "../_helpers/models";
import { mountainsImages } from "../_assets/areas/index";
import { mountainsTerritoryHimalaya } from "./areaPoints";

export const mountainsData: IData = {
  typeOfArea: [
    {
      name: "area_generic_header",
      subname: "area_generic_subtitle",
      description: "teritory_mountains_descr",
      link: "area_generic_appendix",
      index: 0,
      locationX: "-1000%",
      locationY: "-1000%",
      positionTab: "areaGlobe__informations-center",
    },
    {
      slug: "himalayas",
      mapC: mountainsImages.map,
      map: mountainsImages.map,
      name: "teritory_mountains_himalayas_name",
      subname: "teritory_mountains_himalayas_subname",
      description: "teritory_mountains_himalayas_descr",
      link: "area_generic_appendix",
      index: 1,
      locationX: "67%",
      locationY: "39%",
      territory: mountainsTerritoryHimalaya,
      positionTab: "areaGlobe__informations-start",
    },
  ],

  regions: [
    {
      name: "teritory_mountains_himalayas_name",
      subname: "teritory_mountains_himalayas_subname",
      gallery: mountainsImages.photos,
      index: 0,
    },
  ],

  panoramas: [],
};
