import React, { useEffect, useState } from "react";


import {
    useTerritoryContext,
    useTerritoryActionsContext,
} from "../../../context/Terrritory.context";

const SubRoutePicker = () => {

    const { territoryData, activeTerritoryRoute } = useTerritoryContext();
    const { setActiveTerritoryRoute } = useTerritoryActionsContext();

    const [activeId,] = useState<number>(activeTerritoryRoute);

    const currentNamedID = territoryData.routes[activeTerritoryRoute].id;
    const currentPointID = territoryData.routes[activeTerritoryRoute].pointId;
    const currentSubRoutes = territoryData.routes.filter(route => {
        // eslint-disable-next-line eqeqeq
        return route.id == currentNamedID || (route.pointId !== undefined && route.pointId == currentPointID)
    })

    useEffect(() => {
        setActiveTerritoryRoute(activeId);
    }, [activeId, setActiveTerritoryRoute]);

    return (
        <div className="sub_route_picker">
            {currentSubRoutes.length > 1 && (
                <>
                    <div className="line"></div>
                    {currentSubRoutes.map((subRoute, index) => {
                        return (<>
                            <div className={`dot ${subRoute.id === currentNamedID ? "active" : "not-active"}`}><span>{index + 1}</span></div>
                            <div className="line"></div>
                        </>)
                    })}
                </>)}
        </div>
    );
};

export default SubRoutePicker;
