import React from "react";

import { TerritoryInfo } from ".";
import {
  ExtraModal,
  InteractiveMap,
  Legend,
  SliderTrails,
  SubRoutePicker,
} from "./TerritoryPanelElements";
import { useTranslation } from "react-i18next";

import { useTerritoryContext } from "../../context/Terrritory.context";

const TerritoryPanel = () => {
  const { territoryData, territoryToggles } = useTerritoryContext();
  const { t } = useTranslation();

  return (
    <div className="bigpanel">
      {territoryToggles.info ? (
        <TerritoryInfo />
      ) : (
        <>
          <div className="bigpanel__title">
            <h2>{t(territoryData.name)}</h2>
            <h3>{t(territoryData.description)}</h3>
          </div>


          <div className="bigpanel__extraMobile">
            <ExtraModal />
          </div>

          <SliderTrails />

          <div className="bigpanel_subroute_picker">
            <SubRoutePicker />
          </div>

          <div className="bigpanel__content">
            <ExtraModal />
            <InteractiveMap map={territoryData.mapC} />
            {territoryData.slug === "himalayas" && <Legend />}
          </div>
        </>
      )}
    </div>
  );
};

export default TerritoryPanel;
